<template>
  <div class="infoPage">
    <div class="topImg">
      <!--      <img :src="dataInfo.backgroundImage" style="object-fit: cover;" alt="">-->
    </div>

    <div class="conBox">
<!--      <div class="navListBox">{{ titleStr }}> <span>#{{ dataInfo.historyTitle || dataInfo.webTitle || dataInfo.aboutasTitle }}</span> </div>-->
      <div class="navListBox">{{ titleStr }} </div>
      <div class="infoBox">
        <div class="title">
          <div class="tit">{{ dataInfo.historyTitle || dataInfo.webTitle || dataInfo.aboutasTitle }}</div>
<!--          <div class="time">{{ dataInfo.historyCreateTime || dataInfo.dynamicinfomationCreateTime || dataInfo.aboutasCreateTime }}</div>-->
        </div>
        <div class="titleDate">
          <div class="tags"><span v-for="(item,index) in dataInfo.tags">{{item}}</span></div>
<!--          <div class="num el-icon-view">&nbsp;&nbsp;{{ dataInfo.visitNum || 0 }}</div>-->
          <div class="num" v-if="tType == 'CH'">访问量：&nbsp;{{ dataInfo.visitNum || 0 }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;发布时间：&nbsp;{{ dataInfo.historyCreateTime || dataInfo.dynamicinfomationCreateTime || dataInfo.aboutasCreateTime }}</div>
          <div class="num" v-if="tType == 'EN'">Visits：&nbsp;{{ dataInfo.visitNum || 0 }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;release time：&nbsp;{{ dataInfo.historyCreateTime || dataInfo.dynamicinfomationCreateTime || dataInfo.aboutasCreateTime }}</div>
        </div>
        <div class="X"></div>
        <div class="text" v-html="dataInfo.historyContent || dataInfo.webContent || dataInfo.aboutasContent ">

        </div>
      </div>
      <div class="Dynamic">
      </div>
    </div>

    <!--    <div class="paging-device">
          <el-pagination
              layout="prev, pager, next"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="current_page"
              :page-size="per_page"
              :total="totalCount">
          </el-pagination>
        </div>-->
  </div>
</template>

<script>
import {getHistoryInfo} from '@/api/table'
import {getSearchInfo} from "@/api";
export default {
  name: "indexInfo",
  components:{
  },
  data(){
    return {
      tType:'CH',
      url:'',
      dataInfo:{},
      otherlist:[],
      swiperOptionOther: {
        navigation: {
          nextEl: '.DynamicRight',
          prevEl: '.DynamicLeft',
        },
        direction: "horizontal", // 竖向滚动
        // autoHeight: true, // 自适应高度
        slidesPerView: "auto", // 记得这里写 auto 不要默认写1哦
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        // loop: true,

      },
      otherId:0,
      titleStr:''
    }
  },
  created() {
    this.tType = sessionStorage.getItem('textType')
    this.otherId = this.$route.query.id
    console.log(this.$route.matched)
    // console.log(this.$router.options.routes)
    if(this.tType == 'EN'){
      this.titleStr = 'current position：'+ this.$route.matched[0].meta.ENtitle +'>' + this.$route.meta.ENtitle
    }else{
      this.titleStr = '当前位置：'+ this.$route.matched[0].meta.title +'>' + this.$route.meta.title
    }

  },
  mounted() {
    this.createlist()
    // this.getOtherlist(this.$route.query.id)
  },
  methods:{
    createlist(historyId){
      getSearchInfo({
        id: this.$route.query.id,
        // title: this.$route.query.title,
        type: this.$route.query.type
      }).then(res=>{
        console.log(res)
        if (res.code==200) {
          this.dataInfo=res.data
          // this.dataInfo.tags = res.data.tags.split(',')
          if(this.dataInfo.tags && this.dataInfo.tags.trim().length>0){this.dataInfo.tags = res.data.tags.split(',')}
          // this.totalCount=res.total
        }
      })
    },
    getOtherlist(){
      historyList({
        historyType:1,
        historyId: this.otherId
      }).then(res=>{
        console.log(res)
        if (res.code==200) {
          this.otherlist=res.rows
          // this.otherlist.tags = res.rows.tags.split(',')
          // this.otherlist = []
          this.otherlist.forEach((item,index)=>{
            if(item.tags && item.tags.trim().length>0){item.tags = item.tags.split(',')}
            // item.tags = item.tags.split(',')
          })
        }
      })
    },
    goInfo(id){
      return false
      this.createlist(id)
      this.otherId = id
      this.getOtherlist()
    },
    select(){

    },
    changep(){

    }
  }
}
</script>

<style scoped lang="less">
.infoPage{
  width: 100%;
  //height: 800px;
  min-height: 800px;
  //padding: 0 0 0 55px;
  .topImg{
    width: 100%;
    //height: 800px;
    height: 280px;
    //background: #007B8A ;
    overflow: hidden;
    img{
      width: 100%;
    }
  }
  .conBox{
    width: 1300px;
    //margin: -100px auto 0;
    margin: -270px auto 0;
    padding-bottom: 180px;
    position: relative;
    .navListBox{
      height: 70px;
      //background: #F9F9F9;
      border-radius: 40px 40px 0px 0px;
      font-size: 14px;
      font-family: STSongti-SC-Bold, STSongti-SC;
      font-weight: bold;
      color: #333333;
      line-height: 70px;
      padding-left: 50px;
      span{
        color: #007B8A;
      }
    }
    .infoBox{
      width: 100%;
      //background: #FFFFFF;
      //border-radius: 40px 40px 0px 0px;
      padding: 50px;
      .title{
        padding-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .tit{
          font-size: 34px;
          font-family: STSongti-SC-Black, STSongti-SC;
          font-weight: 900;
          color: #333333;
          max-width: 80%;
        }
        .time{
          margin: 20px 0;
          font-size: 14px;
          font-family: STHeitiSC-Medium, STHeitiSC;
          font-weight: 600;
          color: #333333;
        }
      }
      .titleDate{
        margin: 20px 0 30px;
        display: flex;
        align-items: center;
        .tags{
          span{
            font-size: 12px;
            font-family: STSongti-SC-Regular, STSongti-SC;
            font-weight: 400;
            color: #333333;
            padding: 3px 13px 4px 12px;
            margin-left: 10px;
            background: rgba(120, 120, 120, 0.1);
            border-radius: 17px;
            &:nth-child(1){
              margin-left: 0;
            }
          }
        }
        .num{
          font-size: 14px;
          font-family: STHeitiSC-Medium, STHeitiSC;
          font-weight: 600;
          color: #333333;
          margin-left: 30px;
        }
      }
      .X{
        width: 100%;
        height: 1px;
        background: #DDDDDD;
        margin: 0 0 50px 0;
      }
      .text{
        //font-size: 14px;
        font-family: STSongti-SC-Bold, STSongti-SC;
        //font-weight: bold;
        color: #333333;
        line-height: 30px;
      }
      .textBox{
        font-size: 14px;
        font-family: STSongti-SC-Bold, STSongti-SC;
        font-weight: bold;
        color: #333333;
        line-height: 30px;
        margin-bottom: 32px;
      }
      .imgBox{
        display: flex;
        margin-bottom: 200px;
        .left,.right{
          width: 50%;
          //width: 600px;
          //height: 800px;
          img{
            width: 100%;
            height: 100%;
          }
        }
        //.right{
        //  width: 50%;
        //  background: pink;
        //display: flex;
        //flex-wrap: wrap;
        //.item{
        //  flex: none;
        //  width: 50%;
        //  height: 400px;
        //}
        //}
      }
    }

    .Dynamic{
      //background: #F5F5F5;
      padding: 60px 0 0px 0;
      .title{
        width: 1200px;
        margin: 0px auto 30px;
        display: flex;
        justify-content: space-between;
        align-items: end;
        .text{
          font-size: 34px;
          font-family: STSongti-SC-Black, STSongti-SC;
          font-weight: 900;
          color: #333333;
          span{
            color: #337D8D;
          }
        }
        .lookAll{
          font-size: 20px;
          font-family: STSongti-SC-Regular, STSongti-SC;
          font-weight: 400;
          color: #000000;
          cursor: pointer;
          span{
            padding: 5px;
            border-radius: 50%;
            margin: 0 20px;
            font-size: 22px;
            font-weight: bold;
            cursor: pointer;
            &:hover{
              background: #00000022;
              //color: #337D8D;
              color: #043843;
            }
          }
        }
      }
      .DyList{
        width: 1200px;
        margin: auto;
        position: relative;
        &:hover{
          .leftBtn,.rightBtn{
            display: block;
            transition:all 1000ms ease;
          }
        }
        .leftBtn,.rightBtn{
          display: none;
          position: absolute;
          top: 50%;
          left: 0;
          width: 50px;
          height: 80px;
          background: rgba(0,0,0,0.5);
          z-index: 5;
          text-align: center;
          line-height: 80px;
          border-radius:0 10px 10px 0;
          transform: translate(0, -50%);
          font-size: 25px;
          color: #007B8A;
          transition:all 1000ms ease;
          cursor: pointer;
        }
        .rightBtn{
          border-radius: 10px 0 0 10px;
          left: auto;
          right: 0;
          transform: translate(0, -50%);
        }
        /deep/.swiper-container{
          overflow: hidden;
        }
        /deep/.swiper-wrapper{
          display: flex;
          //justify-content: space-between;
          .item-swiper{
            width: 380px;
            margin-right: 30px;
            &:nth-last-child(1){
              margin-right: 0;
            }
          }
        }
        .item{
          flex: none;
          width: 380px;
          min-height: 450px;
          height: 100%;
          background: #FFFFFF;
          border-radius: 20px;
          overflow: hidden;
          cursor: pointer;
          &:hover{
            .img{
              transition:all 1000ms ease;
              //transform: scale(1);
              transform: scale(1.1);
            }
            .con>.tit{
              color: #337D8D;
            }
          }
          .img{
            width: 100%;
            height: 300px;
            position: relative;
            transition:all 500ms ease;
            img{
              width: 100%;
              height: 100%;
            }
            .eye{
              position: absolute;
              top: 16px;
              left: 16px;
              padding: 8px 12px;
              background: #337D8D55;
              border-radius: 5px;
              font-size: 14px;
              font-family: STHeitiSC-Medium, STHeitiSC;
              font-weight: 600;
              color: #ffffff;
            }
          }
          .con{
            padding: 24px;
            .tit{
              font-size: 20px;
              font-family: STSongti-SC-Black, STSongti-SC;
              font-weight: 900;
              color: #333333;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1; //行数
              overflow: hidden;
            }
            .time{
              font-size: 14px;
              font-family: STHeitiSC-Medium, STHeitiSC;
              font-weight: 500;
              color: #999999;
              margin: 16px 0;
            }
            .tags{
              margin: 15px 0 10px;
              display: flex;
              align-items: center;
              width: 100%;
              flex-wrap: wrap;
              span{
                font-size: 12px;
                font-family: STSongti-SC-Regular, STSongti-SC;
                font-weight: 400;
                color: #333333;
                padding: 3px 13px 4px 12px;
                margin-right: 10px;
                background: rgba(120, 120, 120, 0.1);
                border-radius: 17px;
              }
            }
            .conText{
              font-size: 14px;
              font-family: STSongti-SC-Regular, STSongti-SC;
              font-weight: 400;
              color: #999999;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2; //行数
              overflow: hidden;
            }
          }

        }
      }
    }

  }


}
</style>

